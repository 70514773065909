<template>
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="d-flex">
      <div class="side-block mr-5">
        <div class="img-space-shadow">
          <span>{{ computedData.datas.length }}</span>
        </div>
        <div>
          Produits <br />
          existants
        </div>
      </div>
    </div>
    <button class="btn btn-primary" @click="handleNewProduct">
      Créer un produit
    </button>
  </div>
  <modal-component v-model="visible">
    <dilypse-product-modal
      :data-product="dataProduct"
      :new-product="newProduct"
      @created="handleOnCreated"
      @cancel="visible = false"
    />
  </modal-component>
  <modal-component v-model="success">
    <dilypse-affiliate-modal-success
      message="Votre produit a bien été crée, retrouvez-le dans le listing."
      @continue="success = false"
    />
  </modal-component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

import ModalComponent from '@/components/Feedback/Modal/Modal.vue'

import DilypseProductModal from './DilypseProductModal.vue'

import DilypseAffiliateModalSuccess from '../DilypseAffiliateTab/DilypseAffiliateModalSuccess.vue'

interface IProductHeaderData {
  visible: boolean
  success: boolean
  newProduct: {
    timestamp?: number
    new?: boolean
  }
  dataProduct: unknown
}

@Options({
  name: 'DilypseProductHeader',
  props: {
    data: Object,
  },
  components: {
    ModalComponent,
    DilypseProductModal,
    DilypseAffiliateModalSuccess,
  },
  computed: {
    ...mapGetters(['getProductList']),
    computedData() {
      return this.getProductList.dataProduct || { datas: [] }
    },
  },
  watch: {
    data(data) {
      const {
        name,
        id,
        commission_type,
        commission,
        status,
        frequency,
        type,
      } = data.record
      this.dataProduct = {
        product_name: name,
        product_id: id,
        amount_type: commission_type === 'Quantitatif' ? '2' : '1',
        amount: commission,
        status: status ? 1 : 0,
        frequency,
        type,
      }
      this.visible = true
    },
  },
  data(): IProductHeaderData {
    return {
      visible: false,
      success: false,
      newProduct: {},
      dataProduct: {},
    }
  },
  methods: {
    handleNewProduct() {
      this.visible = true
      this.newProduct = {
        timestamp: new Date().getTime(),
        new: true,
      }
    },
    handleOnCreated() {
      this.visible = false
      this.success = true
    },
  },
})
export default class DilypseProductHeader extends Vue {}
</script>
