import { IResponse } from './response'

export interface IDashboardResellerDetails {
  key?: string
  id?: number | string
  date?: string
  visitor: number
  subscribe: number
  subscribe_freemium: number
  gains: number
}

export interface IDashboardResellerListDetails {
  id?: string | number
  complete_name: string
  email: string
  commission: IDashboardResellerDetails
  affiliate?: boolean
  visible?: boolean
}

export interface IDashboardResellerList {
  details?: IDashboardResellerListDetails[]
  all?: IDashboardResellerDetails
  position?: number
  date?: string
}

export interface IDashboardDatas {
  byMonth?: IDashboardResellerList[]
  currentMonth?: IDashboardResellerDetails
  byYear?: IDashboardResellerDetails
  last_month?: IDashboardResellerList
  key?: string
}
export interface IDashboardResellerData extends IResponse {
  datas: IDashboardDatas
}

export interface IAffiliatesDataResult {
  affiliated: {
    affiliated_id?: string
    firstname: string
    lastname: string
    email: string
    phone: string
    status?: boolean
    iban: string
    rib?: string
    created?: string
    affiliate_id?: string
    affiliate_url?: string
  }
  record?: {
    count: number
    gain: number
  }
  id?: string
}

export interface IAffiliatesData extends IResponse {
  datas: {
    result: IAffiliatesDataResult[]
    total_gain?: number
    total_subscribed?: number
  }
}

export interface IAffiliateListData {
  id: string
  name: string
  commission_type: string
  commission: string
  status: boolean
  admin: boolean
}

export interface IProductData extends IResponse {
  datas: IAffiliateListData[]
}

export interface IAffiliateParams {
  affiliate_id?: string
  firstname: string
  lastname: string
  email: string
  phone: string
  rib: string
  iban?: string
}

export interface IProductParams {
  product_id: string
  product_name: string
  amount_type: '1' | '2'
  amount: string
  admin: 0 | 1
  status: 0 | 1
  type: IProductType
  frequency: IProductFrequency
}

export enum IProductFrequency {
  MENSUELLE = 'Mensuelle',
  ANNUELLE = 'Annuelle',
  ONESHOT = 'Oneshot',
}
export enum IProductType {
  LICENCE = 'Licence',
  FORMATION = 'Formation',
  SERVICE = 'Service',
}

export const IProductFequency_PerType: {
  [_ in IProductType]: IProductFrequency[]
} = {
  [IProductType.LICENCE]: [
    IProductFrequency.MENSUELLE,
    IProductFrequency.ANNUELLE,
  ],
  [IProductType.FORMATION]: [IProductFrequency.ONESHOT],
  [IProductType.SERVICE]: [IProductFrequency.ONESHOT],
}
