<template>
  <div class="body-content">
    <dilypse-product-header :data="data" />
    <dilypse-product-list @action="handleActionChange" />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapActions } from 'vuex'

import DilypseProductHeader from './DilypseProductTab/DilypseProductHeader.vue'
import DilypseProductList from './DilypseProductTab/DilypseProductList.vue'

@Options({
  name: 'DilypseResellerProduct',
  components: {
    DilypseProductHeader,
    DilypseProductList,
  },
  mounted() {
    this.setProductDataList()
  },
  data() {
    return {
      data: null,
    }
  },
  methods: {
    ...mapActions(['setProductDataList']),
    handleActionChange(data: unknown) {
      this.data = data
    },
  },
})
export default class DilypseResellerProduct extends Vue {}
</script>
