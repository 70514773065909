<template>
  <card-component title="Liste de vos produits" class="affiliate-list">
    <table-component
      :columns="computedData.columns"
      :data="computedData.datas"
      :rowKey="(product) => `${product.id}${product.frequency}`"
      class="affiliate-list"
      @change="handleSort"
    >
      <template
        v-for="c in computedData.columns"
        :key="c.dataIndex"
        #[c.dataIndex]="{ record }"
      >
        <a href="" title="" v-if="c.dataIndex === 'id'">
          {{ record[c.dataIndex] }}
        </a>
        <span
          v-else-if="c.dataIndex === 'commission_type'"
          :class="c.dataIndex"
        >
          {{ record[c.dataIndex] }}
        </span>
        <span
          v-else-if="c.dataIndex === 'status'"
          :class="{
            status: c.dataIndex === 'status',
            actif: record[c.dataIndex],
          }"
        >
          <i
            class="dicon"
            :class="{
              'dicon-check': record[c.dataIndex],
              'dicon-clock': !record[c.dataIndex],
            }"
          ></i>
          {{ record[c.dataIndex] ? 'Actif' : 'Suspendu' }}
        </span>
        <span v-else-if="c.dataIndex === 'actions'">
          <dropdown-component
            :options="[
              {
                id: 1,
                label: 'Modifier',
              },
              {
                id: 2,
                label: record.status ? 'Suspendre' : 'Activer',
              },
            ]"
            :show-label="false"
            @click="({ id }) => handleChangeAction(id, record)"
          >
            <template #suffix>
              <span></span>
            </template>
            <template #prefix>
              <i class="dot"></i>
            </template>
          </dropdown-component>
        </span>
        <span v-else>
          {{ record[c.dataIndex] }}
        </span>
      </template>
    </table-component>
  </card-component>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import DropdownComponent from '@/components/Navigation/Dropdown/Dropdown.vue'

// eslint-disable-next-line
import { IDropdownProps } from '@/components/Navigation/Dropdown/interface'
// eslint-disable-next-line
import { IProductParams } from '@/intefaces/dashboardReseller'
import { toRaw } from 'vue'
import _ from 'lodash'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'

interface DilypseAffiliateListData {}

@Options({
  name: 'DilypseProductList',
  props: {
    onAction: Function,
  },
  components: {
    CardComponent,
    TableComponent,
    DropdownComponent,
  },
  computed: {
    ...mapGetters(['getProductList']),
    computedData() {
      return this.getProductList || { datas: [] }
    },
  },
  emits: ['action'],
  data(): DilypseAffiliateListData {
    return {}
  },
  methods: {
    ...mapActions(['updateProductStatus', 'setProductDataList']),
    async handleChangeAction(
      id: number,
      record: { id: string; status: boolean }
    ) {
      if (id === 1) {
        this.$emit('action', {
          type: 'edit',
          record: _.cloneDeep(toRaw(record)),
          timestamp: new Date().getTime(),
        })
      } else {
        await this.updateProductStatus({
          product_id: record.id,
          status: record.status ? 0 : 1,
        })
        this.setProductDataList()
      }
    },
    handleSort(sort: ISearchParams) {
      this.setProductDataList({
        key: sort.column.columnName,
        order: sort.order.replace('end', ''),
      })
    },
  },
})
export default class DilypseProductList extends Vue {}
</script>

<style scoped>
a {
  color: #0062ff;
}
.commission_type {
  color: #757491;
}
</style>
