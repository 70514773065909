<template>
  <div class="ant-select-wrapper" ref="antSelect">
    <a-select
      v-model:value="selectedValue"
      class="d-block"
      :class="{
        'not-floating': !!selectedValue,
      }"
      :allow-clear="allowClear"
      @change="handleChange"
      :getPopupContainer="() => $refs.antSelect"
      :disabled="disabled"
    >
      <template #placeholder>
        <span>{{ label }}</span>
      </template>
      <template #suffixIcon class="down">
        <i class="dicon dicon-chevron-down"></i>
      </template>
      <a-select-option
        v-for="item in list"
        :key="item.value"
        :value="item.value"
      >
        {{ item.label }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Select from 'ant-design-vue/lib/select'

@Options({
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
    label: {
      type: String,
      default: 'Selectionner',
    },
    allowClear: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ASelect: Select,
    ASelectOption: Select.Option,
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      list: this.options,
      selectedValue: this.modelValue || undefined,
      disabled: this.disabled || false,
    }
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(value) {
        this.selectedValue = value || undefined
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(options) {
        this.list = options
      },
    },
    disabled: {
      immediate: true,
      handler(disabled) {
        this.disabled = !!disabled
      },
    },
  },
  mounted() {
    this.buildFloatLabel()
  },
  methods: {
    findItem(value: string) {
      return this.list.find((item: { value: string }) => item.value === value)
    },
    handleChange(value: string) {
      this.selectedValue = value
      this.$emit('update:modelValue', this.selectedValue)
      this.$emit('change', { ...this.findItem(this.selectedValue) })
      const $placeholder = this.$refs.antSelect.querySelector(
        '.ant-select-selection__placeholder'
      )
      if (this.selectedValue) {
        $placeholder.classList.add('selected')
      }
    },
    buildFloatLabel() {
      const $antSelect = this.$refs.antSelect.querySelector(
        '.ant-select-selector'
      )
      const selectedClass = this.modelValue !== '' ? 'selected' : ''
      const div = document.createElement('div') as HTMLDivElement
      div.textContent = this.label
      div.className = 'ant-select-selection__placeholder ' + selectedClass
      $antSelect.prepend(div)
    },
  },
})
export default class SelectComponent extends Vue {}
</script>

<style lang="scss">
.ant-select {
  &.ant-select-open {
    .dicon-chevron-down {
      transform: rotate(180deg);
    }
  }
  .ant-select-selection-item {
    flex: 0 0 100%;
  }
}

.ant-select-selection,
.ant-select-selector {
  .ant-select-arrow {
    right: 0px;
    margin-top: 0px;
    transform: translateY(-50%);
    top: 50%;

    .dicon-chevron-down {
      color: theme-color();
      font-size: 19px;
      transition: transform 200ms ease-in-out;
    }
  }
  .ant-select-selection-clear {
    color: #ff7f89;
    font-size: 15px;
    line-height: 15px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
    margin-top: -3px;
    right: 8px;
    border-radius: 50%;
    &:hover {
      color: rgba(255, 127, 137, 0.8) !important;
    }
  }
  .ant-select-selection__placeholder {
    transition: all 500ms ease;
    color: #999;
    flex: 0 0 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    &.selected {
      display: block !important;
      font-weight: 500;
      transform-origin: top left;
      transform: translateY(-50%);
      font-size: 9px !important;
      margin-top: -9px;

      & ~ .ant-select-selection-item {
        transform: translateY(9px);
        color: #4b41b5;
      }
    }
  }
}

.ant-select-selector .ant-select-arrow {
  right: 12px;

  i {
    font-family: 'dilypse-icon' !important;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    padding: 7px 8px;
    text-align: center;
    display: inline-block;
    border-left: 1px solid #f1f1f1;
    font-size: 18px;

    &::before {
      content: '\e906';
    }

    svg {
      display: none !important;
    }
  }

  .select-pos {
    position: absolute;
    z-index: 9;
    top: 17px;
    right: 30px;
  }
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f4f5fa;
  border: 1px solid #dedaff;
  align-items: center;
  padding: 0 20px;
  position: relative;
  border-radius: 10px;
  height: 50px;
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}

.ant-select-selection-rendered {
  margin: 0;
  line-height: 1;
  width: 100%;
}
</style>
