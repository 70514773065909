
import { Options, Vue } from 'vue-class-component'
import SelectComponent from '@/components/DataEntry/Select/Select.vue'
import FloatingFormItem from '@/components/DataEntry/FloatingLabel/FloatingFormItem.vue'
import ButtonValidationComponent from '@/components/General/Button/ButtonValidation.vue'

import {
  // eslint-disable-next-line
  IProductParams,
  IProductFrequency,
  IProductType,
} from '@/intefaces/dashboardReseller'
import { toRaw } from 'vue'
import { mapActions } from 'vuex'
import _ from 'lodash'
import Form from 'ant-design-vue/lib/form'

interface IProductData {
  formData: Partial<IProductParams>
  rules: unknown
  idChanged: boolean
  isNewProduct: boolean
  loading: boolean
}

const rules = {
  product_name: [
    {
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
  product_id: [
    {
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
  amount: [
    {
      required: true,
      message: 'Ce champ est obligatoire',
      trigger: 'blur',
    },
  ],
}

@Options({
  name: 'DilypseProductModal',
  components: {
    SelectComponent,
    FloatingFormItem,
    ButtonValidationComponent,
    AForm: Form,
  },
  props: {
    newProduct: Object,
    dataProduct: Object,
  },
  watch: {
    newProduct: {
      immediate: true,
      handler(value) {
        this.isNewProduct = value.new
        this.formData = {
          product_name: '',
          product_id: '',
          amount_type: '2',
          amount: '',
          type: IProductType.LICENCE,
          frequency: IProductFrequency.ANNUELLE,
        }
      },
    },
    dataProduct: {
      immediate: true,
      handler(value) {
        if (Object.keys(value).length) {
          this.formData = value
          this.isNewProduct = false
        }
      },
    },
  },
  emits: ['created', 'cancel'],
  data(): IProductData {
    return {
      loading: false,
      idChanged: false,
      isNewProduct: false,
      formData: {
        product_name: '',
        product_id: '',
        amount_type: '2',
        amount: '',
        type: IProductType.LICENCE,
        frequency: IProductFrequency.ANNUELLE,
      },
      rules,
    }
  },
  computed: {
    showFrequency() {
      return this.formData.type === IProductType.LICENCE
    },
    frequencyOptions() {
      if (this.formData.type === IProductType.LICENCE) {
        return [
          {
            value: IProductFrequency.MENSUELLE,
            label: IProductFrequency.MENSUELLE,
          },
          {
            value: IProductFrequency.ANNUELLE,
            label: IProductFrequency.ANNUELLE,
          },
        ]
      }
      return [
        {
          value: IProductFrequency.ONESHOT,
          label: IProductFrequency.ONESHOT,
        },
      ]
    },
  },
  methods: {
    ...mapActions(['setNewProduct', 'setProductDataList']),
    handleCreate: async function () {
      try {
        this.loading = true
        const { formProductRef } = this.$refs
        await formProductRef.validate()
        const formData = _.cloneDeep(toRaw(this.formData))

        if (this.isNewProduct) {
          formData.status = 1
        }

        formData.amount = formData.amount.replace(/[^\d.]+/g, '')
        await this.setNewProduct({
          ...formData,
        })
        this.loading = false

        this.setProductDataList()
        this.resetField()

        this.$emit('created', true)
      } catch (e) {
        this.loading = false
      }
    },
    resetField() {
      const keysFormData = Object.keys(toRaw(this.formData))
      const { formProductRef } = this.$refs
      formProductRef.resetFields()
      formProductRef.clearValidate(keysFormData)
    },
    handleChangeName() {
      if (!this.isNewProduct) {
        return
      }
      if (this.formData.product_name === '') {
        this.idChanged = false
      }
      if (!this.idChanged) {
        this.formData.product_id = this.formData.product_name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/\s+/g, '_')
      }
    },
    handleChangeId() {
      this.idChanged = true
    },
    handleCancel() {
      this.$emit('cancel')
      setTimeout(() => this.resetField())
    },
    handleUnitOnFocus() {
      if (this.formData.amount !== '') {
        this.formData.amount = this.formData.amount.replace(/[^\d.]+/, '')
      }
    },
    handleUnitOnBlur() {
      this.handleUnitOnFocus()
      if (this.formData.amount !== '') {
        const { amount, amount_type } = this.formData
        this.formData.amount =
          amount + `€ / ${amount_type === '1' ? 'transaction' : 'unité'}`
      }
    },
    handleChangeType(t: { value: IProductType }) {
      if (t.value !== IProductType.LICENCE) {
        this.formData.frequency = IProductFrequency.ONESHOT
      } else {
        this.formData.frequency = IProductFrequency.ANNUELLE
      }
    },
  },
})
export default class DilypseProductModal extends Vue {}
