
import { Options, Vue } from 'vue-class-component'
import { mapActions, mapGetters } from 'vuex'

import CardComponent from '@/components/DataDisplay/Card/Card.vue'
import TableComponent from '@/components/DataDisplay/Table/Table.vue'
import DropdownComponent from '@/components/Navigation/Dropdown/Dropdown.vue'

// eslint-disable-next-line
import { IDropdownProps } from '@/components/Navigation/Dropdown/interface'
// eslint-disable-next-line
import { IProductParams } from '@/intefaces/dashboardReseller'
import { toRaw } from 'vue'
import _ from 'lodash'
// eslint-disable-next-line
import { ISearchParams } from '@/intefaces/common'

interface DilypseAffiliateListData {}

@Options({
  name: 'DilypseProductList',
  props: {
    onAction: Function,
  },
  components: {
    CardComponent,
    TableComponent,
    DropdownComponent,
  },
  computed: {
    ...mapGetters(['getProductList']),
    computedData() {
      return this.getProductList || { datas: [] }
    },
  },
  emits: ['action'],
  data(): DilypseAffiliateListData {
    return {}
  },
  methods: {
    ...mapActions(['updateProductStatus', 'setProductDataList']),
    async handleChangeAction(
      id: number,
      record: { id: string; status: boolean }
    ) {
      if (id === 1) {
        this.$emit('action', {
          type: 'edit',
          record: _.cloneDeep(toRaw(record)),
          timestamp: new Date().getTime(),
        })
      } else {
        await this.updateProductStatus({
          product_id: record.id,
          status: record.status ? 0 : 1,
        })
        this.setProductDataList()
      }
    },
    handleSort(sort: ISearchParams) {
      this.setProductDataList({
        key: sort.column.columnName,
        order: sort.order.replace('end', ''),
      })
    },
  },
})
export default class DilypseProductList extends Vue {}
