
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

import ModalComponent from '@/components/Feedback/Modal/Modal.vue'

import DilypseProductModal from './DilypseProductModal.vue'

import DilypseAffiliateModalSuccess from '../DilypseAffiliateTab/DilypseAffiliateModalSuccess.vue'

interface IProductHeaderData {
  visible: boolean
  success: boolean
  newProduct: {
    timestamp?: number
    new?: boolean
  }
  dataProduct: unknown
}

@Options({
  name: 'DilypseProductHeader',
  props: {
    data: Object,
  },
  components: {
    ModalComponent,
    DilypseProductModal,
    DilypseAffiliateModalSuccess,
  },
  computed: {
    ...mapGetters(['getProductList']),
    computedData() {
      return this.getProductList.dataProduct || { datas: [] }
    },
  },
  watch: {
    data(data) {
      const {
        name,
        id,
        commission_type,
        commission,
        status,
        frequency,
        type,
      } = data.record
      this.dataProduct = {
        product_name: name,
        product_id: id,
        amount_type: commission_type === 'Quantitatif' ? '2' : '1',
        amount: commission,
        status: status ? 1 : 0,
        frequency,
        type,
      }
      this.visible = true
    },
  },
  data(): IProductHeaderData {
    return {
      visible: false,
      success: false,
      newProduct: {},
      dataProduct: {},
    }
  },
  methods: {
    handleNewProduct() {
      this.visible = true
      this.newProduct = {
        timestamp: new Date().getTime(),
        new: true,
      }
    },
    handleOnCreated() {
      this.visible = false
      this.success = true
    },
  },
})
export default class DilypseProductHeader extends Vue {}
